<script>
import axios from "axios";

const EMAIL_STEP = 1;
const OTP_STEP = 2;

export default {
  name: "ForgotPass",
  //state
  data() {
	return {
		step: EMAIL_STEP,
		emailInput: "",
		otpInput: "",
		newPassInput: "",
		info: "",
		EMAIL_STEP: EMAIL_STEP,
		OTP_STEP: OTP_STEP,
		disableNext: false,
		disablePrev: false,
		reqOTPData: {},
	}
  },
  // actions
  methods: {
	async handleNextStep(){
		this.disableNext = true;
		this.disablePrev = true;
		if(this.step === EMAIL_STEP)
		{
			// send req
			axios
			.post(`/reqOTP`, {
				email: this.emailInput
			})
			.then((response) => {
				// JSON responses are automatically parsed.
				console.log(response);
				this.reqOTPData = response.data;
				++this.step;
				this.info = ""
				this.disableNext = false;
				this.disablePrev = false;
			})
			.catch((e) => {
				console.error(e);
				this.info = e.response.data.message;
				this.disableNext = false;
				this.disablePrev = false;
			});
		}
		else if (this.step === OTP_STEP)
		{
			// send req
			axios
			.post(`/verifyOTP`, {
				...this.reqOTPData,
				newPassword: this.newPassInput,
				OTP: this.otpInput
			})
			.then((response) => {
				// JSON responses are automatically parsed.
				console.log(response);
				++this.step;
				this.info = ""
				this.disableNext = false;
				this.disablePrev = false;
			})
			.catch((e) => {
				console.error(e);
				this.info = e.response.data.message;
				this.disableNext = false;
				this.disablePrev = false;
			});
		}
		else this.$router.push("/login")
	},
	handlePrevStep()
	{
		if (this.step == OTP_STEP) --this.step;
		else
			this.$router.push("/login")
	}
  }
};
</script>

<template>
	<div id="forgot-pass">
		<img src="@/assets/XFarmsLogo.png" class="mt-5 forgot-pass__logo" alt="..."/>
		<h5> Forgot Password </h5>
		<div v-if="step == EMAIL_STEP" class="forgot-pass__container">
			<div class="form-group forgot-pass__form-group mx-auto">
				<label>Email Address</label>
				<input type="email" class="form-control" v-model="emailInput" placeholder="john@exmaple.com" @keyup.enter="handleNextStep()" aria-describedby="emailHelp">
			</div>
		</div>
		<div v-else-if="step == OTP_STEP" class="container">
			<div class="form-group forgot-pass__form-group mx-auto">
				<label>OTP</label>
				<input type="email" class="form-control" v-model="otpInput" placeholder="ABC345" aria-describedby="OTP">
			</div>

			<div class="form-group forgot-pass__form-group mx-auto">
				<label>New password</label>
				<input class="form-control" type="password" v-model="newPassInput" placeholder="" @keyup.enter="handleNextStep()">
			</div>
		</div>	
		<div v-else>
			Password change successful
		</div>
		<div class="my-2 forgot-pass__btn">
			<b-button @click="handlePrevStep()" :disabled="disablePrev">
				Back
			</b-button>
			<b-button @click="handleNextStep()" variant="primary" :disabled="disableNext">
				Next
			</b-button>
		</div>
		<div class="text-danger">
		{{info}}
		</div>
	</div>
	
</template>

<style>
.forgot-pass__form-group{
	width: 250px;
}

.forgot-pass__logo {
	width: 250px;
	margin: 20px 0;
}

.forgot-pass__container {
	padding: 20px 0;
	margin: 20px 0;
}

.forgot-pass__btn * {
	margin: 0 10px;
}

</style>